<template>
  <div class="col-span-12 lg:col-span-8 2xl:col-span-9">
    <div class="intro-y box lg:mt-5">
      <div
        class="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400"
      >
        <h2 class="mr-auto text-base font-medium">{{ $t("permissiоns/services") }}</h2>
      </div>

      <div class="p-5">
        <div class="flex flex-col xl:flex-row">
          <form class="flex-1 mt-6 xl:mt-0" @submit.stop.prevent="onUpdateUser">
            <FieldsFormDisplay
              :collection="collection!"
              :item="props.profileData"
              :fields="fields"
              v-on="fieldsEventListeners"
            />

            <Button
              variant="primary"
              type="submit"
              :disabled="!havePermissionForUpdate"
              class="w-24 mt-3"
              v-tooltip="{
                value: $t('error.not_enough_permission.to_update'),
                disabled: havePermissionForUpdate,
                context: {
                  right: true,
                },
              }"
              >{{ $t("save") }}
            </Button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
type _VTI_TYPE_IItemMeta = {
  [key: string]: unknown;
} | null
type _VTI_TYPE_ItemID = string | number
interface _VTI_TYPE_UserInterface {
  readonly id: _VTI_TYPE_ItemID;
  readonly data: _VTI_TYPE_T;
  readonly meta: _VTI_TYPE_IItemMeta;
  setDataProperty(propertyOrPath: string, data: unknown): IItem;
  getDataProperty(propertyOrPath: string): unknown;
  setMetaProperty(propertyOrPath: string, data: unknown): IItem;
  getMetaProperty(propertyOrPath: string): unknown;
  get role(): RoleInterface;
}
interface Props {
    profileData: _VTI_TYPE_UserInterface;
  }
  import { useI18n } from '#i18n'
  import { UsersGatewayInterface } from '~/entities/user'
  import Button from '~/shared/ui/Button'
  import { iocContainer } from '~/inversify.config'
  import { INJECT_SYMBOLS } from '~/service/inversion-of-control/inject-symbols'
  import { toaster } from '~/service/toaster'
  import { computed } from 'vue'
  import { FieldsFormDisplay, useFieldsEventListeners, useCollectionFieldsIncluding } from '~/entities/field'
  import { useCollecitonsStore } from '~/stores/collections'
  import { usePermissionsStore } from '~/stores/permissions'
  import { PermissionAction } from '~/api/permissions/entity/PermissionInterface'
  
  const props = defineProps<Props>();
  const emit = defineEmits<{
    "update:user": [payload: UserInterface];
  }>();
  const { t } = useI18n();
  const collectionsStore = useCollecitonsStore();
  const collection = computed(() => collectionsStore.getCollection("directus_users"));
  const permissionsStore = usePermissionsStore();
  const havePermissionForUpdate = computed<boolean>(() =>
    permissionsStore.hasCollectionPermission("directus_users", PermissionAction.UPDATE),
  );
  const computedProfileData = computed(() => {
    return props.profileData;
  });
  const { fields } = useCollectionFieldsIncluding("directus_users", computedProfileData, [
    "role",
    "status",
  ]);
  const { fieldsEventListeners } = useFieldsEventListeners(fields, computedProfileData);
  async function onUpdateUser(): Promise<void> {
    try {
      const userApi = iocContainer.get<UsersGatewayInterface>(
        INJECT_SYMBOLS.UsersGatewayInterface,
      );
      const updatedUser = await userApi.updateUser(
        props.profileData.id as string,
        props.profileData,
      );
      toaster().success(t("user_updated"));
      emit("update:user", updatedUser);
    } catch (err) {
      toaster().error(`${t("error_user_update")}: ${err!.message}`);
    }
  }
</script>

